// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-mdx": () => import("./../../../src/pages/about.mdx" /* webpackChunkName: "component---src-pages-about-mdx" */),
  "component---src-templates-feed-list-template-js": () => import("./../../../src/templates/feedListTemplate.js" /* webpackChunkName: "component---src-templates-feed-list-template-js" */),
  "component---src-templates-feed-template-js": () => import("./../../../src/templates/feedTemplate.js" /* webpackChunkName: "component---src-templates-feed-template-js" */)
}

