module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Future Shock","short_name":"futureshock","start_url":"/","background_color":"#9a2e22","theme_color":"#9a2e22","display":"minimal-ui","icon":"src/images/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7b7c005fbb4dedcfe5a570e8f22e4582"},
    },{
      plugin: require('../node_modules/gatsby-plugin-plausible/gatsby-browser.js'),
      options: {"plugins":[],"domain":"futureshock.xyz","customDomain":"stats.futureshock.xyz"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://futureshock.xyz","noQueryString":true,"noHash":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"defaultLayouts":{"default":"/opt/build/repo/src/components/layoutDefault.js"},"remarkPlugins":[null],"gatsbyRemarkPlugins":["gatsby-remark-smartypants","gatsby-remark-embed-video","gatsby-remark-responsive-iframe","gatsby-remark-relative-images","gatsby-remark-copy-linked-files",{"resolve":"gatsby-remark-autolink-headers","options":{"isIconAfterHeader":true}},{"resolve":"gatsby-remark-external-links","options":{"target":"_blank","rel":null}},{"resolve":"gatsby-remark-images","options":{"maxWidth":800,"showCaptions":true,"backgroundColor":"transparent","linkImagesToOriginal":false,"quality":85}}],"lessBabel":false,"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/build/repo"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
